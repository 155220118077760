import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Container, Row, Col, Card } from "react-bootstrap"

import Title from "../components/Title"
import { CallToAction, Layout, Seo } from "../components"

export const query = graphql`
  {
    allDatoCmsPracticeArea(sort: { order: ASC, fields: number }) {
      edges {
        node {
          slug
          icon {
            alt
            gatsbyImageData(placeholder: BLURRED, backgroundColor: "grey")
          }
          id
          excerpt
          title
          seo {
            description
            title
          }
        }
      }
    }

    datoCmsImage(image: { alt: { eq: "Am House St Alban Vermont" } }) {
      image {
        alt
        gatsbyImageData
      }
    }

    allDatoCmsPageIntro(filter: { title: { eq: "Practice Areas Page" } }) {
      edges {
        node {
          id
          info
          title
        }
      }
    }
  }
`

const PraticeAreasPage = ({ data }) => {
  const {
    allDatoCmsPracticeArea: { edges: practiceAreas },
    datoCmsImage: image,
    allDatoCmsPageIntro: { edges: intro },
  } = data

  const seoImage = image.image.gatsbyImageData.images.fallback.src

  return (
    <Layout>
      {practiceAreas.map(({ node }) => {
        const { description } = node.seo

        return (
          <Seo
            title="Kissane Associates Practice Areas"
            description={description}
            image={seoImage}
          />
        )
      })}
      <div className="page-header">
        <Container>
          <SectionHeading className="section">
            <Row>
              <Col>
                <Title title="Practice Areas" />
              </Col>
            </Row>
          </SectionHeading>
        </Container>
      </div>

      <Container>
        <Wrapper className="section">
          <Row>
            <Row>
              <Col>
                {intro.map(({ node }) => {
                  return <h3 className="page-intro">{node.info}</h3>
                })}
              </Col>
            </Row>

            {practiceAreas.map(({ node }) => {
              const { icon, excerpt, title, slug } = node
              const praticeImag = icon.gatsbyImageData

              return (
                <Col
                  md={4}
                  className="card-column flex-fill col-md-6 col-sm-12"
                >
                  <article>
                    <nav>
                      <Link to={`/pratice-areas/${slug}`}>
                        <Card>
                          <Card.Body>
                            <GatsbyImage image={praticeImag} alt={icon.alt} />
                            <h4 className="card-title">{title}</h4>
                            <Card.Text>
                              <div
                                className="card-text-body"
                                dangerouslySetInnerHTML={{
                                  __html: excerpt,
                                }}
                              ></div>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Link>
                    </nav>
                  </article>
                </Col>
              )
            })}
          </Row>
        </Wrapper>
      </Container>

      <CallToAction />
    </Layout>
  )
}

const Wrapper = styled.section`
  .card-column {
    margin-bottom: 2rem;
    display: flex;
  }

  @media only screen and (max-width: 400px) {
    .card-column {
      display: block !important;
    }
  }

  article {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: var(--transition);
    display: contents;
  }

  .card {
    position: relative;
    text-align: center;
    height: 100%;
    position: relative;
    transition: all 0.4s ease;

    :hover {
      background-color: rgba(38, 48, 67, var(--alpha));
      .card-title {
        color: var(--clr-primary-6);
      }

      .card-body > .card-text > .card-text-body {
        color: var(--clr-secondary-1);
      }

      .card-body > .card-text > .card-text-body p span {
        color: var(--clr-grey-3);
      }
    }
  }

  .card .card-title {
    color: var(--clr-grey-1);
    font-weight: 900;
    text-transform: uppercase;
  }

  .card-body::after {
    content: "";
    top: 156px;
    position: absolute;
    background: var(--clr-primary-6);
    height: 30px;
    width: 1px;
    display: inline-block;
    transition: all 0.4s ease;
  }

  .card-body:hover::after {
    top: 0;
    position: absolute;
    background: var(--clr-primary-6);
    transition: all 0.4s ease;
  }

  .card-body:hover > .gatsby-image-wrapper.gatsby-image-wrapper-constrained {
    top: 5rem;
  }

  .gatsby-image-wrapper.gatsby-image-wrapper-constrained {
    width: 6rem;

    &:not(:last-child) {
      margin-bottom: 108px;
    }

    &:hover {
      top: 5rem;
    }
  }
`

const SectionHeading = styled.section`
  @media screen and (max-width: 480px) {
    padding: 4rem 0 0 0;
  }

  h2.section-heading {
    text-align: center;
    color: var(--clr-primary-6);
    padding-top: 7rem;

    @media screen and (max-width: 480px) {
      padding-top: 2rem;
    }
  }
`

export default PraticeAreasPage
